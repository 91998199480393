import {
  FileDoneOutlined,
  FileProtectOutlined,
  FileSyncOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { config } from "../lib";

export const ResetIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
  >
    <path
      d="M7.49063 0.936529C4.03906 0.941217 1.25 3.7334 1.25 7.18497C1.25 9.18497 2.19062 10.9662 3.65313 12.11L3.06719 12.86C3.00313 12.9428 3.0625 13.0631 3.16563 13.0615L5.775 13.049C5.85625 13.049 5.91563 12.9725 5.89531 12.8943L5.27813 10.3584C5.27255 10.3355 5.26064 10.3147 5.24376 10.2983C5.22688 10.2819 5.20571 10.2706 5.1827 10.2658C5.15968 10.2609 5.13575 10.2626 5.11366 10.2707C5.09157 10.2787 5.07221 10.2929 5.05781 10.3115L4.42188 11.1256C4.2625 11.0006 4.10938 10.8647 3.96406 10.7193C3.50722 10.2641 3.14318 9.72439 2.89219 9.13028C2.63125 8.51466 2.5 7.85997 2.5 7.18497C2.5 6.50997 2.63125 5.85528 2.89219 5.23965C3.14375 4.64434 3.50469 4.10997 3.96406 3.65059C4.42344 3.19122 4.95781 2.83028 5.55313 2.57872C6.17031 2.31778 6.825 2.18653 7.5 2.18653C8.175 2.18653 8.82969 2.31778 9.44531 2.57872C10.0406 2.83028 10.575 3.19122 11.0344 3.65059C11.4938 4.10997 11.8547 4.64434 12.1063 5.23965C12.3672 5.85528 12.4984 6.50997 12.4984 7.18497C12.4984 7.85997 12.3672 8.51466 12.1063 9.13028C11.8553 9.72439 11.4912 10.2641 11.0344 10.7193C10.9172 10.8365 10.7953 10.9459 10.6688 11.0506C10.6434 11.0712 10.6272 11.101 10.6237 11.1335C10.6202 11.1659 10.6296 11.1985 10.65 11.224L11.2656 12.0131C11.3094 12.0678 11.3891 12.0772 11.4438 12.0334C12.8516 10.8865 13.75 9.14122 13.75 7.18497C13.75 3.73028 10.9469 0.931842 7.49063 0.936529Z"
      fill="black"
      fillOpacity="0.85"
    />
  </svg>
);

export const SubmissionTypeIcon = ({ type = 1, style }) => {
  if (type === config.submissionType.monitoring) {
    return <FileSyncOutlined style={style} />;
  }
  if (type === config.submissionType.verification) {
    return <FileDoneOutlined style={style} />;
  }
  if (type === config.submissionType.certification) {
    return <FileProtectOutlined style={style} />;
  }
  return <FileTextOutlined style={style} />;
};
